import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import CO2StorageAssessmentAdditionalBasinsImage from "../../../../assets/images/release-notes/CO2StorageAssessmentAdditionalBasins.png";
import CashFlowChartImage from "../../../../assets/images/release-notes/CashFlowChart.png";
import PressureDataDSTPlotsImage from "../../../../assets/images/release-notes/PressureDataDSTPlots.jpg";
import WellEconomicAggregationsImage1 from "../../../../assets/images/release-notes/WellEconomicAggregations_1.png";
import WellEconomicAggregationsImage2 from "../../../../assets/images/release-notes/WellEconomicAggregations_2.png";
import WellEconomicAggregationsImage3 from "../../../../assets/images/release-notes/WellEconomicAggregations_3.png";

const Version20241219: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20241219"}
      onChange={handleChange("Version20241219")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2024.12.19
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          12/19/2024
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release introduces several new features and data enhancements,
          including DST Plots (DST+), Haynesville Well Economics Data, Vertical
          Wells Economic Data, Well Economic Aggregations, Cashflow Chart, and
          additional CO2 Storage Assessment Basins.
        </CustomTypography>
      </AccordionDetails>
      <AccordionContent>• Pressure Data - DST Plots</AccordionContent>
      <AccordionSubContent>
        • Users can now access all available DST+ plots for each well directly
        from the well panel.
      </AccordionSubContent>
      <AccordionSubContent>
        • To view these plots, click on the MORE INFO icon located on the mini
        well card for each well and select Pressure Data.
      </AccordionSubContent>
      <AccordionSubContent>
        • The following plots are available for each DST:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ INCREMENTAL SHUT-IN <br />
          ▪ HORNER PLOT <br />
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionImage
        src={PressureDataDSTPlotsImage}
        width="100%"
        draggable={false}
      />

      <AccordionContent>• Haynesville Well Economics Data</AccordionContent>
      <AccordionSubContent>
        • The newly added Haynesville well economics data are now accessible in
        Well Data Analytics.
      </AccordionSubContent>

      <AccordionContent>• Vertical Wells Economic Data</AccordionContent>
      <AccordionSubContent>
        • Recently added vertical wells economic data are now available in Well
        Data Analytics.
      </AccordionSubContent>

      <AccordionContent>• Well Economic Aggregations</AccordionContent>
      <AccordionSubContent>
        • This feature enables users to aggregate economic models at the asset
        level for up to 200,000 wells, providing streamlined access to
        asset-level economic data seamlessly integrated with TGS Well Data
        Analytics.
      </AccordionSubContent>
      <AccordionSubContent>
        • To use this feature, users can select the desired wells, click on the
        ANALYZE icon, and choose Asset Economics. After reviewing aggregated
        cost inputs and economic outputs, such as IRR, breakeven price, NPV, and
        payback period, users can export the financial model for the selected
        wells to an Excel file.
      </AccordionSubContent>
      <AccordionImage
        src={WellEconomicAggregationsImage1}
        width="100%"
        draggable={false}
      />
      <AccordionImage
        src={WellEconomicAggregationsImage2}
        width="100%"
        draggable={false}
      />
      <AccordionImage
        src={WellEconomicAggregationsImage3}
        width="100%"
        draggable={false}
      />

      <AccordionContent>• Cash Flow Chart</AccordionContent>
      <AccordionSubContent>
        • Users can now access a Cash Flow chart for either a single well or a
        selected group of wells. The chart includes the following data:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ Monthly Total Revenue (Unhedged), $ <br />
          ▪ Monthly Total Operating Costs, $ <br />
          ▪ Monthly Asset-Level FCF, $ <br />
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionImage src={CashFlowChartImage} width="100%" draggable={false} />

      <AccordionContent>
        • CO2 Storage Assessment Additional Basins
      </AccordionContent>
      <AccordionSubContent>
        • The recently added Illinois, Gulf Coast and Michigan Basins Storage
        Capacity layers are now available in Well Data Analytics under Culture
        Layers in MAP SETTINGS.
      </AccordionSubContent>
      <AccordionImage
        src={CO2StorageAssessmentAdditionalBasinsImage}
        width="100%"
        draggable={false}
      />
    </Accordion>
  );
};

export default Version20241219;
